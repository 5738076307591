<!-- 面试管理  -->
<template>
  <div class="box">
    <div class="InterviewManagement">
      <div class="caozuo"></div>
      <div class="shijianzhou">
        <div
          class="shjijianzhou_item"
          v-for="(item, index) in data"
          :key="index"
        >
          <div class="title">{{ item.cdate }}</div>
          <div class="el-card box-card is-always-shadow">
            <!---->
            <div class="el-card__body">
              <div class="card_item" v-for="(t, i) in item.list" :key="i">
                面试时间:
                <span style="font-weight: bold"> {{ t.ctime }}</span>
                面试人: <span>{{ t.truename }}</span>

                <span>
                  <el-button
                    class="btn"
                    style="width: 50px"
                    size="mini"
                    v-on:click.stop="on_delete(t.id)"
                    >删除</el-button
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="el-loading-mask" style="display: none">
        <div class="el-loading-spinner">
          <svg viewBox="25 25 50 50" class="circular">
            <circle
              cx="50"
              cy="50"
              r="20"
              fill="none"
              class="path"
            ></circle></svg
          ><!---->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { get_audition, get_audition_del } from "@/api/tool";
import Bottom from "../../components/bottom.vue";
export default {
  //import引入的组件需要注入到对象中才能使用

  components: { Bottom },
  data() {
    //这里存放数据
    return {
      data: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //删除
    on_delete(id) {
      let data = {
        id: id,
      };
      this.$confirm("您确定删除吗？")
        .then((_) => {
          get_audition_del(data).then((res) => {
            if (res.code === 0) {
              this.data = res.data;

              console.log(res.data);

              this.loading = false;

              this.getData();
            }

            // console.log(res)
          });
        })
        .catch((_) => {});
    },

    //查询
    getData() {
      get_audition().then((res) => {
        if (res.code === 0) {
          this.data = res.data;

          console.log(res.data);

          this.loading = false;
        }

        // console.log(res)
      });
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */

.box {
  @include padding;
  min-width: 1100px;
  padding-bottom: 20px;
  min-height: 70vh;
}
.wrap {
  padding: 20px 50px;
  min-height: 80vh;

  // overflow: auto;
  // height: calc(90vh - 100px);
  width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: $radius;
  box-shadow: $box-shadow;
  // position: relative;
}

.InterviewManagement {
  min-height: 80vh;
  padding: 30px 50px 100px;
  max-width: 1200px;
}
.shijianzhou {
  width: 1050px;
  border-left: 2px solid #f90;
  padding-left: 12px;
  margin-left: 50px;
}
.shjijianzhou_item {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
.title {
  position: absolute;
  line-height: 20px;
  top: 20px;
  margin-top: -10px;
  left: -105px;
  z-index: 10;
  font-weight: 700;
}
.title:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 48%;
  margin-top: -6px;
  right: -18px;
  border: 1px solid #f90;
  border-radius: 50%;
}
.title:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: -15px;
  background: #f90;
  border-radius: 50%;
}
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-card__body {
  padding: 20px;
}
.card_item {
  padding: 4px 0;
  font-size: 14px;
  letter-spacing: 2px;
}
.card_item span {
  font-size: 16px;
  margin-right: 20px;
}
.el-loading-mask {
  position: absolute;
  z-index: 2000;
  background-color: hsla(0, 0%, 100%, 0.9);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.el-card {
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
</style>
