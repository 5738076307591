<!-- 页脚 页面 -->
<template>
  <div class="bottom">
    <div class="box">
      <div class="logo" @click="dealHandel($router.push('/'))">
        <img src="../assets/images/layout/logofooter.png" alt />
      </div>

      <div class="right">
        <div class="spans">
          <span
            @click="dealLink(item, index)"
            v-for="(item, index) in list"
            :key="index"
            href
            >{{ item.name }}</span
          >
        </div>
        <p>
          ©2020&nbsp;咖聘&nbsp;&nbsp;京ICP备19005205号&nbsp;&nbsp;
          <span>
            <img
              style="height: 15px"
              src="../assets/images/layout/zhong.png"
            />京公网安备11011102001237号
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //我的页面最下方
      mine: false,
      list: [
        {
          name: `公司展示`,
          to: "/company",
        },
        {
          name: `招聘工具`,
          to: "/tool",
        },
        {
          name: `猎场`,
          to: "/resume",
        },
        {
          name: `推广互动`,
          to: "/promotion",
        },
        {
          name: `我的`,
          to: "/me",
        },
      ],
    };
  },
  methods: {
    dealLink(item, index) {
      console.log(item, this);
      this.$router.push(item.to);
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped>
.bottom {
  width: 100%;
  min-width: 1200px;
  height: 100px;
  background: #f9f9f9;
  /* position: fixed;
  bottom: 0; */
  /* margin-top: 15px; */
}
/* .bottom.bottom_mine {
  position: absolute;
  bottom: 0;
} */
.box {
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.right {
  width: 695px;
  height: 43px;
  position: relative;
}

.spans {
  width: 400px;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  margin-left: 300px;
}

p {
  font-size: 14px;
  color: #ccc;
  position: absolute;
  right: 0;
}

.spans span {
  font-size: 14px;
  color: #939393;
}

.fen {
  position: relative;
}
.block {
  position: absolute;
  left: 50%;
}
</style>